import isEmpty from 'lodash.isempty';

// components
import VideoPlayButton from '../VideoPlayButton';
import PreviewImageContent from '../PreviewImageContent';
import MiVideoView from './MiVideoView';

// utils
import { useUCConsent, VideoConsent } from '../../../features/cookie-consent';
import { useIsMobile } from 'components/App/SizeProvider';

interface MiVideoContainerViewProps {
  displayVideo: boolean;
  previewImageDisplay?: string;
  domId: string | null;
  playVideo?: (e?: any) => void;
  paused: boolean;
  hasModal: boolean;
  videoPlayButtonLabel: string;
  buttonText: string;
  hasError: boolean;
  isModal: boolean;
  previewImage: string;
  content: Record<string, any>;
  className: string;
  openModal: () => void;
  isSectionReference: boolean;
  isVideoContent: boolean;
}

const MiVideoContainerView = ({
  previewImage,
  displayVideo,
  videoPlayButtonLabel = 'Play Video',
  paused,
  content,
  previewImageDisplay,
  playVideo,
  className,
  hasModal,
  openModal,
  buttonText,
  domId,
  hasError,
  isModal,
  isSectionReference,
  isVideoContent = false,
}: MiVideoContainerViewProps) => {
  const isMobile = useIsMobile();
  const { videoTitle, autoplay, subheadline, textColor, videoVariant, video, pictureAlt } = content;
  const ucConsent = useUCConsent();

  if (isEmpty(video)) return null;
  const showPreviewImage = !isModal || (!displayVideo && autoplay === 'no');
  const hasButtonText = videoVariant && videoVariant === 'buttonText';
  const hasPlayButton = videoVariant && videoVariant === 'buttonOnly';
  const allowedToHaveButton = (autoplay !== 'yes' && !isModal && !hasError) || isMobile;
  const hasText = videoTitle || subheadline;
  const styles = showPreviewImage
    ? {
        style: {
          backgroundImage: `url(${previewImage})`,
        },
      }
    : {};

  if (ucConsent.Comfort === false) {
    return (
      <div className={`${className}__video`}>
        <VideoConsent type={'mi24'} />
      </div>
    );
  }
  return (
    <div className={`${className}__video`}>
      <div
        className={`${className}__video-module`}
        role="img"
        aria-label={pictureAlt}
        title={pictureAlt}
        {...styles}
      >
        {(hasModal || hasButtonText || (!hasModal && hasText)) && showPreviewImage && paused && (
          <PreviewImageContent
            subheadline={subheadline}
            textColor={textColor}
            className={className}
            playVideo={hasModal ? openModal : playVideo}
            videoTitle={videoTitle}
            videoPlayButtonLabel={videoPlayButtonLabel}
            buttonText={buttonText}
            allowedToHaveButton={allowedToHaveButton}
            isPreview={undefined}
            isSectionReference={isSectionReference}
            isVideoContent={isVideoContent}
          />
        )}
        {!hasModal && (
          <div
            className={`video-wrapper${
              displayVideo && ucConsent.Comfort === true ? ' show-video' : ''
            }`}
          >
            <MiVideoView
              previewImageDisplay={previewImageDisplay}
              videoId={video?.videoId}
              domId={domId}
              className={className}
            />
            {((hasPlayButton && showPreviewImage) ||
              (!hasButtonText && !hasText && !displayVideo) ||
              (displayVideo && paused && !isMobile)) &&
              ucConsent.Comfort &&
              (paused || !hasModal) &&
              allowedToHaveButton &&
              className !== 'c-text-video' &&
              className !== 'hero-image-c' &&
              className !== 'c-video-content' && (
                <VideoPlayButton
                  videoPlayButtonLabel={videoPlayButtonLabel}
                  playVideo={playVideo}
                />
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MiVideoContainerView;
