import React from 'react';
import isEmpty from 'lodash.isempty';

// Components
import { InnerHtml } from '../InnerHtml/inner-html';
import { BUTTON_COLORS, Button } from '../Button/Button';

/**
 * Render the video previewimage content
 *
 * @param className Section specific classname
 * @param textColor
 * @param videoTitle
 * @param playVideo Function to play video
 * @param videoPlayButtonLabel
 * @param subheadline
 * @param buttonText
 * @param buttonText
 * @returns {*}
 * @constructor
 */

interface PreviewImageContentProps {
  className: string;
  textColor: string;
  videoTitle: string;
  playVideo: () => void;
  videoPlayButtonLabel: string | null;
  subheadline: string | null;
  buttonText: string | null;
  allowedToHaveButton: boolean;
  isSectionReference: boolean;
  isVideoContent: boolean;
}

function PreviewImageContent({
  className,
  videoTitle,
  playVideo,
  allowedToHaveButton,
  isSectionReference,
  textColor = 'white',
  subheadline = null,
  videoPlayButtonLabel = null,
  buttonText = null,
  isVideoContent = false,
}: Readonly<PreviewImageContentProps>) {
  const buttonContent = isEmpty(buttonText) ? videoPlayButtonLabel : buttonText;
  const color = textColor || 'white';

  return (
    <div
      className={`${className}__video-title  ${className}__video-title--${color} grid-container`}
    >
      {subheadline && <InnerHtml as="span" content={subheadline} className="video-subheadline" />}
      <InnerHtml
        as="span"
        className="h2"
        content={videoTitle}
        previewId={isVideoContent ? '#st_videoTitle' : undefined}
        isSectionReference={isSectionReference}
      />
      {allowedToHaveButton && (
        <div className="video-preview-container__video-title__button">
          <Button
            color={textColor === 'white' ? BUTTON_COLORS.WHITE : BUTTON_COLORS.BLACK}
            onClick={playVideo}
          >
            {buttonContent}
          </Button>
        </div>
      )}
    </div>
  );
}

export default PreviewImageContent;
